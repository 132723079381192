import React from "react";
import {Helmet} from 'react-helmet'

interface PageHelmetProps {
    pageTitle: string
}

const PageHelmet: React.FC<PageHelmetProps> = (props) => {
    return(
        <React.Fragment>
            <Helmet>
                <title>{props.pageTitle}</title>
                <meta name="description" content="eDirectory" />
            </Helmet>
        </React.Fragment>
    )
}


export default PageHelmet;

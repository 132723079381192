import React from 'react'
const CallAction = () => {
    return (
        <div></div>
        // <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-lg-12">
        //                 <div className="inner text-center">
        //                     <span>PLAN YOUR NEXT STAY</span>
        //                     <h2>Find competitive room rates</h2>
        //                     <a className="rn-button-style--2" href="https://www.hilton.com/en/hotels/seapses-embassy-suites-seattle-downtown-pioneer-square/"><span>book now</span></a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
export default CallAction;
import React from 'react';
import ReactDOM from 'react-dom';
import './font.css'
import './index.scss';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
  ,rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

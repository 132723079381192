import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface QrCodeProps {
    image: string;
    height: number;
    width: number;
    externalUrl: string;
    text: string;
}

export const QRCode: React.FC<QrCodeProps> = (props) => {
    const handleClick = () => {
        window.location.assign(props.externalUrl);
    }

    return (
        <React.Fragment>
            <Grid container direction='column'>
                <Grid item>
                    <img src={props.image} alt="QR Code" height={props.height} width={props.width} onClick={handleClick}/>
                </Grid>
                <Grid item>
                    <Typography color='textSecondary' variant='caption' align='center'>{props.text}</Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
import React from "react";

import {
  Theme,
  makeStyles,
  Grid,
  Typography,
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  Paper,
  Box,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import { Link, useHistory } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { isSafari } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "50px",
  },
  logo: {
    height: "auto",
    width: "auto",
    maxWidth: "250px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "150px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  listItem: {
    marginTop: "5px",
    marginBottom: "25px",
  },
  secondaryList: {
    marginLeft: "10%",
  },
  messageBody: {
    marginRight: "10%",
    marginLeft: "10%",
  },
  mobileText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
  },
  icon: {
    marginBottom: "10px",
  },
  header: {
    fontSize: "2.0243rem",
    fontFamily: "Avendia Std Regular,Din Regular,Arial",
    fontWeight: 400,
    lineHeight: 1.235,
  },
  bodyText: {
    // fontSize: '1.125rem',
    // fontFamily: 'Avendia Std Regular,Din Regular,Arial',
    // fontWeight: 500,
    // lineHeight: 1.7,
  },
}));

export const HotelOperations: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <br />
      <Grid container>
        <Link to="/" style={{ margin: "20px" }}>
          <Button variant={"secondary"}>Go Back</Button>
        </Link>
      </Grid>
      <div className={classes.messageBody}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <img
              src="/assets/images/current-operations/ESSlogo.png"
              className={classes.logo}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            <div>
              {!isSafari ? (
                <div className={classes.header}>Dear Valued Guests</div>
              ) : (
                <div></div>
              )}
            </div>
            <br />
            <div>
              <Typography variant="h6" className={classes.bodyText}>
                Welcome to the Embassy Suites Seattle Downtown Pioneer Square.
                As a result of the novel coronavirus (COVID-19), the hospitality
                industry is experiencing an impact to business that will require
                the following changes within the hotel operation:
              </Typography>
            </div>
            <div>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.mobileText}
                    primary={`Per King County Public Health Department, whether or not you are vaccinated,
                                             please wear masks in all indoor public settings like including our hotel lobby,
                                             dining establishments, fitness center, pool facilities and at large outdoor events
                                             with 500 or more people, and in crowded outdoor settings where people cannot
                                             remain at least 6 feet apart.`}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <Typography
                    variant="h6"
                    component={"div"}
                    onClick={() => {
                      window.location.href =
                        "https://www.doh.wa.gov/Portals/1/Documents/1600/coronavirus/Secretary_of_Health_Order_20-03_Statewide_Face_Coverings.pdf";
                      return null;
                    }}
                  >
                    All unvaccinated staff and visitors are required to wear
                    masks in indoor public spaces, with reasonable
                    accommodations for those with disabilities, as described in
                    the
                    <Typography
                      color="secondary"
                      display="inline"
                      variant="h6"
                      component={"div"}
                    >
                      {" "}
                      State Health Order on Face Coverings.{" "}
                    </Typography>
                    Any organization may choose to require all staff and
                    visitors to wear masks.
                  </Typography>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <Typography
                    variant="h6"
                    component={"div"}
                    onClick={() => {
                      window.location.href =
                        "https://www.doh.wa.gov/Emergencies/COVID19/ClothFaceCoveringsandMasks/ClothFaceCoveringsandMasksFAQ#heading94120";
                      return null;
                    }}
                  >
                    If a person is required to wear a face covering under
                    Washington state guidance, a business must require the
                    person to wear a mask and prohibit entry if they decline.
                    For more information, visit the
                    <Typography
                      color="secondary"
                      display="inline"
                      variant="h6"
                      component={"div"}
                    >
                      {" "}
                      Washington State Department of Health website.
                    </Typography>
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Breakfast will be served Monday-Friday between 6am – 9am and Saturday and Sunday between 7am – 10:30am.`}
                  />
                </ListItem>
                <List className={classes.secondaryList}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.icon}>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Full Hot and Cold Buffet options are now available.`}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.icon}>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Made-to-order Omelets are also now available.`}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.icon}>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`Seating is now at full capacity.`}
                    />
                  </ListItem>
                </List>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Please Join us for our Evening Reception on the 2nd floor -  Enjoy Complimentary Drinks & Snacks Nightly from 5pm – 6:30pm. Premium upgrades available.`}
                  />
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`The Fitness Center is now open on the 8th floor 24 hours a day.`}
                  />
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`The Hotel Pool is now open daily from 6am – 11pm. `}
                  />
                </ListItem>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Housekeeping Stayover Service will be provided every fourth day of your stay.  Please contact the Front Desk for any additional items are needed during your stay.`}
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`13 Coins is fully open for indoor seating and take out Sunday - Thursday 7am – 11pm and Friday - Saturday 7am – 2am.`}
                  />
                </ListItem>
                <List className={classes.secondaryList}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.icon}>
                      <FiberManualRecordOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={`For off hour deliveries, we recommend the following food delivery services: Uber Eats, Postmates, Door Dash, Grub Hub and Caviar.`}
                    />
                  </ListItem>
                </List>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Self Service laundry is available on the 8th floor.`}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Valet laundry is on limited service, please contact the Front Desk for the most up to date information. `}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.icon}>
                    <FiberManualRecordIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Valet parking is currently not available.`}
                  />
                </ListItem>
              </List>
            </div>
            <br />
            <Typography variant="h6">
              We apologize for any inconvenience this may cause. If we can do
              anything to make your stay more comfortable certainly reach out to
              the Front Desk.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <br />
            <Typography variant="h6">Best Regards,</Typography>
            <br />
            <img src="/assets/images/gmmessage/MikeSig.jpg" />
            <Typography variant="h6">Mike Andring</Typography>
            <Typography variant="h6">General Manager</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {Worker, SpecialZoomLevel, Slot, PageChangeEvent, Viewer, OpenFile} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { useMediaQuery } from 'react-responsive';
import Button from 'react-bootstrap/esm/Button';
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';


interface PdfViewerProps {
    match?: any;
    name?: string;
}

// const renderToolbar = (toolbarSlot: ToolbarSlot): React.ReactElement => {
//     return (
//         <div
//             style={{
//                 alignItems: 'center',
//                 display: 'flex',
//                 width: '100%',
//             }}
//         >
//             <div
//                 style={{
//                     alignItems: 'center',
//                     display: 'flex',
//                     flexGrow: 1,
//                     flexShrink: 1,
//                     justifyContent: 'center',
//                 }}
//             >
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.previousPageButton}
//                 </div>
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
//                 </div>
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.nextPageButton}
//                 </div>
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.zoomOutButton}
//                 </div>
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.zoomPopover}
//                 </div>
//                 <div style={{ padding: '0 2px' }}>
//                     {toolbarSlot.zoomInButton}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const layout = (
//   isSidebarOpened: boolean,
//   container: Slot,
//   main: Slot,
//   toolbar: RenderToolbar,
//   sidebar: Slot,
// ): React.ReactElement => {
//   return defaultLayout(
//       isSidebarOpened,
//       container,
//       main,
//       toolbar(renderToolbar),
//       sidebar,
//   );
// };


interface PdfParams {
    name: string;
}


const MyDocument: React.FC<PdfViewerProps> = (props) => {
    const params = useParams<PdfParams>();
    const file = props.name ?? params.name;
    const isMobile = useMediaQuery({query: '(max-device-width: 425px)'})
    const defaultScale = isMobile ? "PageWidth" as SpecialZoomLevel : 1.70

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    const pdfUri = () => {
      switch(file){
        case "guest-directory":
            if (isMobile){
                return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Guest%20Directory-Mobile.pdf";
            } else {
                return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Is's%202020%20Guest%20Directory%20Inserts%207.11.20.pdf";
            }
        // case "hotel-operations":
        //     break;
        // case "general-manager-welcome":
        //     break;
        case "covid-update":
            return 'https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/COVID%2019%20Sept%202020.pdf';
        case 'parking':
            return 'https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Parking%20Flyer.pdf';
        // case 'hotel-technology':
        //     break;
        case '13-coins':
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Embassy%20Suites%20Seattle%20-%20Contract%20for%205G%20at%20255%20S%20King%20St..pdf";
        case 'zephyr':
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Zephyr-Menu.pdf"
        case 'food-and-beverage':
            return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Embassy%20Suites%20Seattle%20-%20Contract%20for%205G%20at%20255%20S%20King%20St..pdf"
        default:
          return "https://edirectoryfiles.blob.core.windows.net/e-directory-pdf/Guest%20Directory-Mobile.pdf";
      }
    }

    const handlePageChange = (e: PageChangeEvent) => {
    }

    return (
      <div style={{marginTop: '5px'}}>
        <Link to="/" style={{margin: '20px'}}>
            <Button variant={"secondary"}>
                Go Home
            </Button>
        </Link>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.js">
            <div style={{ height: '900px', paddingTop: '20px'}}>
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                }}
            >
            <Toolbar>
                    {(props: ToolbarSlot) => {
                        const {
                            CurrentPageInput,
                            Download,
                            EnterFullScreen,
                            GoToNextPage,
                            GoToPreviousPage,
                            NumberOfPages,
                            Print,
                            ShowSearchPopover,
                            Zoom,
                            ZoomIn,
                            ZoomOut,
                        } = props;
                        return (
                            <>
                                {/* <div style={{ padding: '0px 2px' }}>
                                    <ShowSearchPopover />
                                </div> */}
                                <div style={{ padding: '0px 1px' }}>
                                    <ZoomOut />
                                </div>
                                <div style={{ padding: '0px 1px' }}>
                                    <Zoom />
                                </div>
                                <div style={{ padding: '0px 1px' }}>
                                    <ZoomIn />
                                </div>
                                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                    <GoToPreviousPage />
                                </div>
                                <div style={{ padding: '0px 2px', width: '3rem' }}>
                                    <CurrentPageInput />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    /  <NumberOfPages />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <GoToNextPage />
                                </div>
                                <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                    <EnterFullScreen />
                                </div>
                                {/* <div style={{ padding: '0px 2px' }}>
                                    <Download />
                                </div>
                                <div style={{ padding: '0px 2px' }}>
                                    <Print />
                                </div> */}
                            </>
                        );
                    }}
                </Toolbar>
                </div>
                <Viewer
                  fileUrl={pdfUri()}
                  defaultScale={defaultScale}
                  onPageChange={handlePageChange}
                  plugins={[toolbarPluginInstance]}
                />
            </div>
        </Worker>
      </div>
    )
}

export default MyDocument
import React, { useState } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

interface ContactUsState {
  messageSent: boolean,
  error?: string,
  sendingMessage: boolean;
}

export const ContactUs: React.FC = () => {
    const [message, setMessage] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [state, setState] = useState<ContactUsState>({messageSent: false, sendingMessage: false});


    const postKipsuMessage = async () => {
        setState({...state, sendingMessage: true})
        const body = {
            text: message,
            reservationRoom: phoneNumber
        }
        const result = await axios.post('/api/property/message', body)
        if (result.status === 200){
          setState({...state, messageSent: true, sendingMessage: false})
        } else {
          setState({messageSent: false, sendingMessage: false, error: "Error sending message"})
        }
    }

    const submitContactUs = async (e: React.FormEvent<HTMLElement>) => {
      e.preventDefault();
      await postKipsuMessage();
    }

    const showError = (() => {
      if (state.error && state.error.length > 0) {
        return <div>{state.error}</div>
      }
      return <div></div>
    })()

    const showForm = (() => {
      return (
        <div>
            <Form onSubmit={e => submitContactUs(e)} >
                <Form.Group controlId="contactUs.phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="phoneNumber" placeholder="206-111-1111" onChange={e => setPhoneNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group controlId="contactUs.message">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={e => setMessage(e.target.value)}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                {
                  state.sendingMessage
                  ? <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  : <div></div>
                }
            </Form>
            {showError}
        </div>
      )
    })()

    return (
        <div>
          {
            state.messageSent
            ? <div>Message Sent</div>
            : showForm
          }
        </div>
    )
}
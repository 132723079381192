import React from 'react';
import ServiceTile, { ServiceTileProps } from '../home/components/ServiceTile'
import { FaTv, FaAmazon } from 'react-icons/fa'
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        background: 'url(/assets/images/bg/background.jpg) no-repeat center center fixed',
        backgroundSize: 'cover',
        minHeight: '680px'
        // top: 75,
        // left: 0,
        // height: '100%',
        // width: '100%',
    }, 

}));

const TechList: ServiceTileProps[] = [
    {
        icon: <FaTv />,
        title: 'My Away',
        description: 'Mobile TV Remote.',
        href: '/technology/my-away',
    },
    {
        icon: <FaAmazon />,
        title: 'Alexa',
        description: 'Your personal digital assistant.',
        href: '/technology/alexa',
    },
]

export const TechDirectory: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Link to="/" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <Grid container justify={"center"}>
                {TechList.map( (val , i) => (
                        <ServiceTile key={i} {...val}/>
                ))}
            </Grid>
        </div>
    )
}
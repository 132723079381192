import { Tab, Tabs, Theme, makeStyles, AppBar, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import React from 'react';
import { TabPanel } from '../../component/common/tab-panel';
import MyDocument from '../pdf-viewer';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
    //  background: 'url(/assets/images/bg/background.jpg) no-repeat center center fixed',
    //   backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      margin: 0
    },
    tab: {
        fontFamily: 'DIN Regular',
        marginTop: '5px'
    }
  }));

interface FandBParams {
    loc: string;
}

export const FoodAndBeverage: React.FC = () => {
    const params = useParams<FandBParams>();
    const file = params.loc;

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(file.toUpperCase() === "ZEPHYR" ? 0 : 1);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      console.log(file)
      setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        console.log(file)
        setValue(index);
    };
    
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Zephyr" className={classes.tab}/>
                    <Tab label="13 Coins" className={classes.tab}/>
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0}>
                    <MyDocument name={"zephyr"}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MyDocument name={"13-coins"}/>
                </TabPanel>
            </SwipeableViews>
        </div>
    )
}
import React from 'react';

export interface ServiceTileProps {
    icon: JSX.Element,
    title: string,
    description: string,
    img?: string,
    href: string,
    targetBlank?: boolean
}

const ServiceTile: React.FC<ServiceTileProps> = (props) => {
    const service = props
    let target = '';
    let rel=''
    if (service.targetBlank){
        target = '_blank'
        rel = 'noopener noreferrer'
    }
    return (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 ">
            <a className="text-center" href={service.href} target={target} rel={rel}>
                <div className="service service__style--2">
                    {
                        service.img
                        ? <div className="image">
                            <img src={"assets/images/service/"+service.img} alt={service.title + " image"}/>
                          </div>
                        : <div className="icon">
                            {service.icon}
                          </div>
                    }

                    <div className="content">
                        <h3 className="title">{service.title}</h3>
                        <p>{service.description}</p>
                    </div>
                </div>
            </a>
        </div>
    )
}

export default ServiceTile
import { Grid, List, ListItem, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Typography } from '@material-ui/core';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        background: 'url(/assets/images/bg/background.jpg) no-repeat center center fixed',
        backgroundSize: 'cover'
    },
    image: {
        maxHeight: "500px",
        width: 'auto',
        marginBottom: '10px',
        boxShadow: "12px 12px 29px #555"
    },
    video: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    mobileVideo: {

    },
    icon: {
        maxWidth: '100px',
        height: '100px',
        margin: 5,
        boxShadow: "12px 12px 29px #555"
    },
    iconGrid: {
        // marginRight: '10%',
        marginLeft: '8.125%'
    },
    iconText: {

    }
}));

export const MyAway: React.FC = () => {
    const isMobile = useMediaQuery({query: '(max-device-width: 425px)'})
    const classes = useStyles();
    return (
        <div style={{overflow: 'hidden'}} className={classes.root}>
            <Grid container>
                <Link to="/technology" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <div>
                <ReactPlayer
                    url='https://youtu.be/4tfXDGupbI8'
                    controls={true}
                    playing={true}
                    height={(() => (isMobile ? '100%' : undefined))()}
                    width={(() => (isMobile ? '100%' : undefined))()}
                    className={classes.video}
                />
            </div>
            <div>
                <Typography variant='h3' style={{marginTop: '25px'}} align="center">
                    To control your TV
                </Typography>
                <List style={{minWidth: 300, maxWidth:1100, margin: 'auto'}}>
                    <ListItem>
                        <Typography>
                            Start by saying “Alexa, ask the hotel to turn the TV on.”
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Open your phone’s camera app to scan the QR code on the TV.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Choose which room you would like to control.
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            Use your phone as the remote.
                        </Typography>
                    </ListItem>
                    
                    <ListItem>
                        <Typography>
                            Press the triangle button with three dots in the top right to switch between the remote controls and the number pad.
                        </Typography>
                    </ListItem>
                </List>

            </div>
            <div style={{marginTop: '20px'}}>
                <Typography variant='h5' style={{minWidth: 300, maxWidth:1100, margin: 'auto auto auto 10px'}}>
                    The remote can perform the following functions:
                </Typography>
            </div>
            <div className={classes.iconGrid}>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/PowerToggle.png"} className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Turn the TV on/off.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/MoreOptions.png"} className={classes.icon} />
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography>Switch between the two remote control pages.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/Control.png"} className={classes.icon} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Scroll in the direction pressed.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/OK.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Select the highlighted option.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/MainMenu.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Return to the main menu.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ArrowLeft.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Return to the previous menu.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/Exit.png"} className={classes.icon} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Exit the current view.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/VolumeUp.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Volume Up</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/VolumeDown.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Volume Down</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/Mute.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Mute</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ChannelUp.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Channel Up</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ChannelDown.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Channel Down</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ChromeCast.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Go to the Chromecast menu.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ClosedCaption.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Toggle Closed Captioning.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Grid container alignItems="center">
                            <Grid item xs={6} md={4}>
                                <img src={"/assets/images/icons/guest-tek/ChooseTV.png"}  className={classes.icon}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Choose which TV to control.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {/* <TableContainer>
                <Table style={{minWidth: 300, maxWidth:1100, margin: 'auto'}}>
                    <TableBody>

                    <TableRow>
                        <TableCell>Red Power Button</TableCell>
                        <TableCell>Turn the TV on/off</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Up/right/down/left Arrows</TableCell>
                        <TableCell>Scroll in the direction pressed</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Top Right Triangle</TableCell>
                        <TableCell>Go to Remote/Numpad</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>OK</TableCell>
                        <TableCell>Select the highlighted option</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Menu (green button)</TableCell>
                        <TableCell>Return to the main menu</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Back (left of menu button)</TableCell>
                        <TableCell>Return to previous menu</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Volume Up</TableCell>
                        <TableCell>Increase the volume by one</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Volume Down</TableCell>
                        <TableCell>Decrease the volume by one</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Mute</TableCell>
                        <TableCell>Set the volume to zero</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Plus</TableCell>
                        <TableCell>Increase the channel by one</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Minus</TableCell>
                        <TableCell>Decrease the channel by one</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Chromecast (Purple button)</TableCell>
                        <TableCell>Go to the Chromecast menu</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CC</TableCell>
                        <TableCell>Toggle Closed Captioning</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Blue Computer Icon</TableCell>
                        <TableCell>Chose which TV to control</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> */}
            <Grid container spacing={10} justify="center" style={{marginTop: '20px'}}>
                <Grid item>
                    <img alt="remote" src={process.env.PUBLIC_URL + '/image001.jpeg'}  className={classes.image} />
                    <Typography align="center">
                        Remote
                    </Typography>
                </Grid>
                <Grid item>
                    <img alt="numpad" src={process.env.PUBLIC_URL + '/image002.jpeg'} className={classes.image}/>
                    <Typography align="center">
                        Numpad
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
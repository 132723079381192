import React from 'react';

import { Theme, makeStyles, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { isSafari } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: '50px'
    },
    gmImage: {
        height: 'auto',
        width: 'auto',
        maxWidth: '250px',
        [theme.breakpoints.down("md")]: {
            maxWidth: '150px',
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: '20px'
        }
    },
    signature: {

    },
    messageBody: {
        marginRight: '10%',
        marginLeft: '10%'
    },
    mobileBody: {
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    },
    largeBody: {
        [theme.breakpoints.down("md")]: {
            display: 'none'
        }

    },
    header: {
        fontSize: '2.0243rem',
        fontFamily: 'Avendia Std Regular,Din Regular,Arial',
        fontWeight: 400,
        lineHeight: 1.235
    }
  })
);

export const GMMessage: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Link to="/" style={{margin: '20px'}}>
                    <Button variant={"secondary"}>
                        Go Back
                    </Button>
                </Link>
            </Grid>
            <div className={classes.messageBody}>
                <Grid container alignItems='center' justify='center'>
                    <Grid item sm={3}>
                        <Grid container alignContent='center' alignItems='center' justify='center'>
                            <Grid item>
                                <img src="/assets/images/gmmessage/MikeHS.jpg" className={classes.gmImage}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={9}>
                        <Grid container>
                            <Grid item>
                                {
                                    !isSafari
                                    ? <div className={classes.header}>To Our Valued Guests</div>
                                    : <div></div>
                                }
                            </Grid>
                        </Grid>
                        <br/>
                        <Typography >
                            On behalf of the entire Embassy Suites Seattle Family, welcome to the
                            Embassy Suites Seattle Downtown – Pioneer Square and thank you for
                            choosing us. We are thrilled to have this opportunity to serve you,
                            shine the light and extend the warmth of hospitality as Conrad Hilton
                            originally imagined.
                        </Typography>
                        <br/>
                        <Typography>
                            Whether you are here for business, pleasure or a combination of both, I
                            hope that you will find time to enjoy our world-class amenities,
                            stunning views of the Seattle skyline, great dining options in Zephyr and
                            13 Coins, and the distinguished service from the many hospitality heroes
                            that I’m so fortunate to work with each day.
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <br/>
                        <Typography>
                            Finally, I cannot properly welcome any guest to the hotel without recognizing that this hotel is in the Pioneer Square
                            neighborhood, the heart of the historic Seattle Downtown. We are surrounded by the retail core to the north,
                            Chinatown and the International District to the east, SODO district to the south which is home to Lumen Field and TMobile
                            Park, and the waterfront to the west; all of which rest on the ancestral lands of the Coast Salish people. We
                            recognize, honor and respect the indigenous people of this land, their practices; past, present and future. We
                            recognize, honor, and respect this land. After all, we are all guests on this planet.
                        </Typography>

                        <br/>
                        <Typography>
                            I truly hope you have a memorable experience. Please reach out to the front desk, Manager on Duty, or myself if
                            there is anything we can do to make you more comfortable.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item>
                        <br/>
                        <Typography>
                            Yours in Service,
                        </Typography>
                        <img src="/assets/images/gmmessage/MikeSig.jpg"/>
                        <Typography>
                            Mike Andring, CHA
                        </Typography>
                        <Typography>
                            General Manager
                        </Typography>
                    </Grid>
                </Grid>

            </div>
        </div>
    )
}
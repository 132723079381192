import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './views/home';
import MyDocument from './views/pdf-viewer';
import Header from './component/header/Header';
import Helmet from "./component/common/Helmet";
import Footer from './component/footer/Footer';
import { ContactUs } from './contact';
import MyVideo from './views/video-viewer';
import { Technology } from './views/technology';
import { colors, createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { GMMessage } from './views/gm-message';
import { HotelOperations } from './views/hotel-operations';
import { MyAway } from './views/technology/my-away';
import { Alexa } from './views/technology/alexa';
import { TechDirectory } from './views/technology/tech-directory';
import { isSafari } from 'react-device-detect';
import { FoodAndBeverage } from './views/food-and-beverage';

const ChromeFonts = ["Avendia Std Regular","Din Regular","Arial"].join(',')

const SafariFonts = ["Avendia Std Regular","Arial"].join(',')


let theme = createTheme({
  palette: {
    primary: {
      main: `#1b1464`
    }
  },
  typography: {
    fontFamily: isSafari ? SafariFonts : ChromeFonts
  },
  overrides: {
    MuiListItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px',
        fontFamily: 'DIN Regular'
      }
    },
    MuiListItemText: {

      root: {
        marginBottom: '0px'
      }
    }
  }
})
theme = responsiveFontSizes(theme);
theme.overrides = {
  ...theme.overrides,
  MuiListItemText: {
    primary: {
      fontSize: 20,
      lineHeight: 1.7,
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
        lineHeight: 1.7,
      }
    }
  },
  MuiTypography: {
    body1: {
      lineHeight: 1.7
    },
    h6: {
      lineHeight: 1.7
    },
    h5: {
      lineHeight: 1.7
    },
    h3: {
      lineHeight: 1.7
    },
    colorTextSecondary: {
      color: 'white'
    }
  }
}

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme} >
    <BrowserRouter basename={"/"}>
      <Helmet pageTitle="eDirectory" />
      {/* Start Header Area  */}
      <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
      {/* End Header Area  */}
      <div className="main">
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/view/:name`} render={(props) => <MyDocument {...props} />} />
          <Route exact path={`${process.env.PUBLIC_URL}/watch/:name`} render={(props) => <MyVideo {...props} />} />
          <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={ContactUs} />
          <Route exact path={`${process.env.PUBLIC_URL}/technology`} component={TechDirectory} />
          <Route exact path={`${process.env.PUBLIC_URL}/food-and-beverage/:loc`} component={FoodAndBeverage} />
          <Route exact path={`${process.env.PUBLIC_URL}/our-gm`} component={GMMessage} />
          <Route exact path={`${process.env.PUBLIC_URL}/hotel-operations`} component={HotelOperations} />
          <Route exact path={`${process.env.PUBLIC_URL}/technology/alexa`} component={Alexa} />
          <Route exact path={`${process.env.PUBLIC_URL}/technology/my-away`} component={MyAway} />

        </Switch>
      </div>
      {/* Start Footer Style  */}
      <Footer />
      {/* End Footer Style  */}
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF } from "react-icons/fa";
import { QRCode } from '../common/qr-code';

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/EmbassySuitesSeattlePS/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/embassysuitesseattle/?hl=en'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/embassyseattle?lang=en'},
]

const Footer = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <div className="row">
                                    <div className='col'>
                                    <a href="/">
                                    <img src="/assets/images/logo/logo_trans.png" alt="Logo images" style={{minWidth: 175, maxHeight: 200, maxWidth: 175, width:'50%', margin: '0 0 0 0'}}/>
                                </a>
                                    </div>
                                    <div className='col'>
                                        <QRCode image='/assets/images/qr/hhonors-qr-code.png' height={100} width={100} externalUrl='https://www.hilton.com/en/hilton-honors/join/?OCODE=E292W' text='Join Hilton Honors' />
                                    </div>
                                </div>
                                {/* <a href="/">
                                    <img src="/assets/images/logo/logo_trans.png" alt="Logo images" style={{minWidth: 100, maxHeight: 200, maxWidth: 175, width:'50%', margin: '0 0 0 0'}}/>
                                </a>
                                 */}
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <QRCode image='/assets/images/qr/hhonors-qr-code.png' height={100} width={100} externalUrl='https://www.hilton.com/en/hilton-honors/join/?OCODE=E292W' text='Enroll for Hilton benefits and rewards'/>
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2020 Net Tech Inc. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default Footer;